// eslint-disable-next-line no-unused-vars
import React, { useState, Fragment, useRef, useEffect } from "react";
import { t } from "../../Translation/Translation";
import { IdeaboxUrl } from "../../Services/ApiEndPoints";
import { DataTranslations } from "../../Services/ApiEndPoints";
import { AllLocations } from "../../Services/ApiEndPoints";
import { UserProfile } from "../../Services/ApiEndPoints";
import { APICALL as AXIOS } from "../../Services/AxiosServices";
import ImageUploading from "react-images-uploading";

import IdeaboxPopup from "../../Utilities/Popup/ideaboxPopup";
import Popup from "../../Utilities/Popup/modelPopup";
import { useHistory } from "react-router-dom";
import Files from "react-files";
// import { auto } from "@popperjs/core";
import "./ideabox.css";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";
import PhoneInput from "react-phone-input-2";
import { Select } from "antd";
import DatePicker from 'react-datepicker';
import { components } from "react-select";
import { APICALL } from "../../Services/ApiServices";
import benji from "../../Static/images/Benji.png";
import backBtn from "../../Static/images/Back_Button.png";
import moment from 'moment';

const Ideabox = (props) => {
  // defining states for title and description
  const [locationList, setlocationList] = useState([]);
  const [submitterlocationList, setsubmitterlocationList] = useState([]);
  const [contactList, setcontactList] = useState([]);
  const [defLocation, setdefLocation] = useState([]);
  const [dateOfJoin, setdateOfJoin] = useState("");
  const [idea, setIdea] = useState("");
  const [location, setLocation] = useState([]);

  // const [selectedItem, setselectedItem] = useState([]);
  const [ideaDescription, setIdeaDescription] = useState("");
  // defining states for dynamic field urls
  const [url, setUrl] = useState("");
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [images, setImages] = React.useState([]);
  const [popupstate, setPopupState] = useState(false);
  const [popupstate1, setPopupState1] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [documents, setDocuments] = useState([]);
  const history = useHistory();
  const ref = useRef();
  const [urlState, seturlState] = useState(false);
  const [selectedLocation , setselectedLocation] = useState([]);
  const [selectedIdeaLocation , setselectedIdeaLocation] = useState([]);
  const [selectedItem, setselectedItem] = useState([]);
  const [userName , setuserName] = useState("");
  const [defuserName , setdefuserName] = useState("");
  const [phoneNumber , setphoneNumber] = useState("");
  const [userNumber , setuserNumber] = useState("");
  const [workEmail , setworkEmail] = useState("");
  const [defMail ,setdefMail] = useState("");
  const [workStatus , setworkStatus] = useState("");
  const [userLang, setuserLang] = useState("");
  const [activeLanguage, setActiveLanguage] = useState([]);
  const [locationError, setLocationError] = useState(false);
  const [contactStatus, setcontactStatus] = useState(false);
  const [ideaboxSubtitles , setideaboxSubtitles] = useState([]);
  const [ideaboxSubtitle , setideaboxSubtitle] = useState("");
  // const [set];//handleJoindate
  const formData = new FormData();
  const [isToggled, setIsToggled] = useState(false);

//  useEffect(() => {
//   if(isToggled == false){
//     let langStatus = (userLang == null || userLang == undefined || userLang == "") ? "en" : userLang;
//     localStorage.setItem('activeLanguage',langStatus)
//     setActiveLanguage(langStatus);
//   }
//  },[]
//  );
  let resulting_data = [];
  const options = [
  { value: 'en', label: t('English') },
  { value: 'fr', label: t('French') },
  { value: 'nl', label: t('Dutch') },
];
useEffect(() => {
       if(!JSON.parse(localStorage.getItem("translations"))){
       //Getting translation and storing in the localstorage..
        APICALL.service(DataTranslations, "GET", "", 0, 0).then((result) => {
          localStorage.setItem("translations", JSON.stringify(result));
        });
       }
  },[]);
  let get_id;
  let localLang;
  get_id = localStorage.getItem("id");
  localLang = localStorage.getItem("activeLanguage");

    useEffect(() => {
      if (get_id !== null) {
        let data = {
        user_id: get_id,
      }
      APICALL.service(AllLocations, "POST", data).then((result) => {
          const locationList1 = [];
          setideaboxSubtitles(result["ideaSubtitle"]);
          if(localLang === 'en'){
            setideaboxSubtitle(result["ideaSubtitle"].en);
          }
          else if (localLang === 'fr'){
            setideaboxSubtitle(result["ideaSubtitle"].fr);
          }
          else if(localLang == 'nl'){
            setideaboxSubtitle(result["ideaSubtitle"].nl)
          }
          if(result["locations"].length > 0){
            setlocationList(result["locations"]);
            const locationdata = result["locations"]
            const labelToRemove = 'Allow to all';
            const submitterLocations = locationdata.filter(item => item.label !== labelToRemove);
            setsubmitterlocationList(submitterLocations);
            const contactMode = [
              { value: "email", label :t("E-mail")},
              { value: "mobile", label: t("Mobile") }
            ];
            setcontactList(contactMode);
          }
      });}

      else if(get_id === null){
        let data = {
          user_id: 0,
        }
        APICALL.service(AllLocations, "POST", data).then((result) => {
          const locationList1 = [];
          if(localLang === 'en'){
            setideaboxSubtitle(result["ideaSubtitle"].en);
          }
          else if (localLang === 'fr'){
            setideaboxSubtitle(result["ideaSubtitle"].fr);
          }
          else if(localLang == 'nl'){
            setideaboxSubtitle(result["ideaSubtitle"].nl)
          }
          if(result["locations"].length > 0){
          setlocationList(result["locations"]);
          const locationdata = result["locations"]
          const labelToRemove = 'Allow to all';
          const submitterLocations = locationdata.filter(item => item.label !== labelToRemove);
          setsubmitterlocationList(submitterLocations);
          const contactMode = [
            { value: "email", label :t("E-mail")},
            { value: "mobile", label: t("Mobile") }
          ];
          setcontactList(contactMode);
          }
      });
        setIsToggled(true);
    }
  }, []);

  useEffect(() => {
    var pro_id = localStorage.getItem("id");
    if (pro_id !== null) {

    APICALL.service(UserProfile + pro_id, "GET").then(result => {
      if (result !== null) {
      const value = Object.keys(result.location)[0];
      const label = Object.values(result.location)[0];
      const obj = {
        "value":value,
        "label":label
      };
      const dob = new Date(result.birthDate);
      const name = result.firstName +" "+ result.lastName;
      const phone = result.workPhoneNumber;
      const email = result.workEmail;
      const userlang = result.active_language.value;
      setuserLang(userlang);
      setActiveLanguage(userlang);
      setuserName(name);
      setdefuserName(name);
      // setdefDob(dob);
      setselectedLocation(value);
      setdefLocation(value);
      setphoneNumber(phone);
      setuserNumber(phone);
      setworkEmail(email);
      setdefMail(email);
    }
  });
}

}, []);

useEffect(() => {
  console.log(ideaboxSubtitles)
  if(isToggled == false){
    let langStatus = (userLang == null || userLang == undefined || userLang == "") ? "en" : userLang;
    localStorage.setItem('activeLanguage',langStatus)
    setActiveLanguage(langStatus);
  }
 },[]
 );

  // images change handler
  const onChange = (imageList, addUpdateIndex) => {
    const maxImageSize = 5 * 1024 * 1024;
    const validImages = imageList.filter((image) => image.file.size <= maxImageSize);
    if (validImages.length === imageList.length) {
      setImages(imageList);
    } else {
        setMessage(t("Image size should not exceed 5mb."));
        setTitle(t("Alert"));
        setError(false);
        setPopupState(true);
      return
    }
    // setImages(imageList);
  };

 // file change handler
 const onFilesChange = (files) => {
  // setDocuments(files);
  const maxFileSize = 10 * 1024 * 1024;

  const validFiles = files.filter((file) => file.size <= maxFileSize);
  if (validFiles.length === files.length) {
    setDocuments(files);
  } else {
    setMessage(t("Uploaded file size should not exceed 10MB."));
    setTitle(t("Alert"));
    setError(false);
    setPopupState(true);
    return
  }
};

const onFilesError = (error, file) => {
  setMessage(t("File size is greater than 10mb"));
  setTitle(t("Warning"));
  setError(true);
  setPopupState1(true);
};

const onImagesError = (error, file) => {
  setMessage(t("Image size is greater than 10mb"));
  setTitle(t("Warning"));
  setError(true);
  setPopupState1(true);
};


  // file removing
  const filesRemoveOne = (file) => {
    ref.current.removeFile(file);
  };

  //   handling input change event for url
  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  const handleNameInput =(event) => {
    setuserName(event.target.value);
  }

  const handleEmailInputChange = (event) => {
    setworkEmail(event.target.value);
  }

  const handlephoneInputChange = (phone) => {
    setphoneNumber(phone);
  }
  //   handling input change event for (idea and description)
  const handleInput = (event) => {
    if (event.target.name === "idea") {
      setIdea(event.target.value);
    } else {
      setIdeaDescription(event.target.value);
    }
  };

  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedIdeaLocation || selectedIdeaLocation.length === 0) {
      setLocationError(true);
      return; // Stop further execution
    }
    //!selectedItem || selectedItem.length === 0
    if (!isToggled && (!selectedItem || selectedItem.length === 0)) {
      setcontactStatus(true);
      return; // Stop further execution
    }
    if(isToggled == false){
      createFormData();
    }
    else if(isToggled == true){
      createAnonymousFormData();
    }
    AXIOS.service(IdeaboxUrl, "POST", formData)
      .then((result) => {
        setMessage(result.message);
        setTitle(t("Success"));
        setPopupState(true);
        setError(false);
        clearStates();
      })
      .catch((error) => {
        console.log(error , "this is the error");
        setMessage(t("Error adding idea, please try again"));
        setTitle(t("Alert"));
        setError(true);
        setPopupState(true);
      });
  };

  //after response 200 clearing state
  const clearStates = () => {
    setIdea("");
    setIdeaDescription("");
    setImages([]);
    // selectedItem("");
    if(localStorage.getItem("id") == null || isToggled){
      setselectedLocation([]);
    }
    setselectedIdeaLocation([]);
    ref.current.removeFiles();
    setUrl("");
    setselectedItem([]);
    setdateOfJoin("");
  };
  //close popup
  const closePopup = () => {
    setMessage("");
  };
  // constructingform data
  const createFormData = () => {
    formData.append("user_id", localStorage.getItem("id"));
    formData.append("title", idea);
    formData.append("description", ideaDescription);
    formData.append("location",selectedIdeaLocation);
    formData.append("name",userName);
    formData.append("phone",phoneNumber);
    formData.append("email",workEmail);
    formData.append("preffered-contact",selectedItem);
    formData.append("dateOfJoin",dateOfJoin);//activeLanguage
    formData.append("lanCode",activeLanguage);
    appendImageToForm();
    appendDocumentToForm(); // console.log(handleJoindate);
    // console.log(dateOfBirth);
    appendUrlToForm();
    // printing what is their in form
    // for (var key of formData.entries()) {
    //     console.log("formdata", key[0] + ', ' + key[1]);
    // }
  };
  const createAnonymousFormData = () => {
    // formData.append("user_id", localStorage.getItem("id"));
    formData.append("title", idea);
    formData.append("description", ideaDescription);
    formData.append("location",selectedIdeaLocation);
    formData.append("name",userName);
    formData.append("phone",phoneNumber);
    formData.append("email",workEmail);
    formData.append("preffered-contact",selectedItem);
    formData.append("dateOfJoin",dateOfJoin);
    formData.append("lanCode",activeLanguage);
    //preffered-contact
    appendImageToForm();
    appendDocumentToForm(); // console.log(handleJoindate);
    // console.log(dateOfBirth);
    appendUrlToForm();
    // printing what is their in form
    // for (var key of formData.entries()) {
    //     console.log("formdata", key[0] + ', ' + key[1]);
    // }
  };
  // appending images to form from state
  const appendImageToForm = () => {
    Object.keys(images).map((key) => {
      formData.append("ideaboximage[" + key + "]", images[key].file);
    });
  };
  // appending documents to form
  const appendDocumentToForm = () => {
    Object.keys(documents).map((key) => {
      formData.append("documents[" + key + "]", documents[key]);
    });
  };
  // appending videos to form from state
  const appendUrlToForm = () => {
    formData.append("videourl", url);
  };
  /***
   * Store url in session storage for back button functionality
   */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl("/ideabox");
      seturlState(true);
    }
  }, []);

  //Remove the last item from array and push the url for back button
  function updateFormData(date) {
    // handleJoindate(date);
  }
  // console.log(handleJoindate);
  // console.log(dateOfBirth);
  function historyBack() {
    let response = BackButton();
    localStorage.setItem('activeLanguage',userLang);
    history.push(response);
  }
  const handleToggle = () => {
    setIsToggled(!isToggled);
    if (isToggled) {
      setselectedLocation(defLocation);
      setphoneNumber(userNumber);
      setworkEmail(defMail);
      setuserName(defuserName);
      // let langStatus = (userLang == null || userLang == undefined || userLang == "") ? "en" : userLang;
      // console.log(userLang,"nas");
      localStorage.setItem('activeLanguage',userLang)
      setActiveLanguage(userLang);
      setselectedIdeaLocation([]);
      setideaboxSubtitle(ideaboxSubtitles[userLang]);
    }
    else{
      setselectedLocation([]);
      setselectedIdeaLocation([]);
      setphoneNumber("");
      setworkEmail("");
      setuserName("");
      setcontactStatus(false);
      setselectedItem([]);
      localStorage.setItem('activeLanguage',options[0].value)
      setActiveLanguage(options[0].value);
      setideaboxSubtitle(ideaboxSubtitles[options[0].value]);
    }
  };
  function handleLocation(data) {
    setselectedLocation([data]);
    setLocationError(false);
  }
  function handleIdeaLocation(data){
    setselectedIdeaLocation([data])
    setLocationError(false);
  }
  function handleLangMode(data){
    const selectedLanguage = data;
    setideaboxSubtitle(ideaboxSubtitles[data]);
    setActiveLanguage([data]);
    localStorage.setItem('activeLanguage',[data])
    history.push('/ideabox');
  }

useEffect(() => {
  localStorage.setItem('activeLanguage', userLang);
    return () => {
      localStorage.setItem('activeLanguage', userLang);
    };
  }, [userLang]);

  function handleContactMode(data) {
    setselectedItem([data]);
    setcontactStatus(false);
  }

  const handleJoindate = (date) => {
    setdateOfJoin(date);
  };
  const pro_id = localStorage.getItem("id");
  const isDisabled = !!pro_id;

  return (
    <div
      className="ideabox-container"
      style={{ overflowX: "hidden", overflowY: "auto", height: "100vh" }}
    >
     <div className="row">
     <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4 d-flex justify-content-between">
        <p className="ideabox-back position-relative" onClick={historyBack}>
          <img src={backBtn} alt="bak-btn" className="img-fluid" />
        </p>
        {backbuttonUrl && (
          <BackButtonComponent backButtonLink={backbuttonUrl} />
        )}
        <div className="pagetitle textcolor1 text-center col-md-9">
          <h1 className="pagetitle textcolor1 text-center">{t("Ideabox")}</h1>
          <p className="d-none d-md-block">{(ideaboxSubtitle)}</p>
        </div>
        {/* <h1 className="pagetitle textcolor1 text-center col-md-9">{t("Ideabox")}</h1> */}
        <div className="col-md-2 language-dropdown pr-0 pr-lg-2">
                <label className="textcolor1 d-none d-md-block" htmlFor="language">
                  {t("Select language")}
                </label>
                <Select
                  name="language"
                  id="language"
                  className="lan-select select-bar"
                  multi={false}
                  options={options}
                  onChange={(e) => handleLangMode(e)}
                  showNewOptionAtTop={false}
                  defaultValue={userLang}
                  isClearable={true}
                  value={activeLanguage}
                  placeholder={t("--Select--")}
                />
        </div>
      </div>
     </div>
      <br></br>

      <div className="post position-relative">
      <span className="benji-img-sm d-block d-md-block d-lg-none text-center">
            <img src={benji} alt="benji" className="img-fluid"/>
          </span>
        <form
          className="fieldsWrapp ideaboxform"
          id="form"
          onSubmit={(e) => handleSubmit(e)}
        >
          {pro_id != null &&(
         <div className="row">
           <div className="form-group col-md-12 float-right">
            <div className="float-right">
            <label class="switch">
              <input type="checkbox" onClick={handleToggle} />
              <span class={isToggled ? "slider round" : "slider-on  round"}></span>
            </label>
            <span> {t("Anonmyous")}</span>
            </div>
          </div>
         </div>)}
         <div className="row">
          {!isToggled &&
            <div className="form-group col-lg-12">
                  <label className="textcolor1 addlunchlable">{t("Name")}</label>
                  <input
                    name="name"
                    type="text"
                    id="name"
                    value={userName}
                    onChange={(event) => handleNameInput(event)}
                    className="form-control"
                    // placeholder={t("Name...")}
                  />
            </div>
          }
          {!isToggled &&
            <div className="form-group rounded-3 col-lg-12">
                        <label className="textcolor1 addlunchlable" htmlFor="email">
                          {t("Email address")}
                        </label>
                        <input
                          name="email"
                          type="text"
                          id="email"
                          value={workEmail}
                          onChange={(event) => handleEmailInputChange(event)}
                          className="form-control"
                          // placeholder={t("Eamil address...")}
                        />
            </div>
          }
          {!isToggled &&
            <div className={"form-group col-lg-12 col-12 my-4"}>
                        <h5 className="textcolor1 font-weight-normal addlunchlable"htmlFor="phone">
                          {t("Phone number")}
                        </h5>
                        <h5 className="font-weight-normal">
                          <PhoneInput
                            name="phone"
                            id = "phone"
                            country={"be"}
                            // value={phoneNumber}
                            value={phoneNumber}
                            onChange={(phone) => handlephoneInputChange(phone)}
                            updateField
                            className="form-control"
                            placeholder={t("phone number...")}
                          />
                        </h5>{" "}
            </div>
          }
          {!isToggled &&
            <div className="form-group col-lg-12">
                        <label className="textcolor1 addlunchlable">
                          {t("How long have you been working at Bleckmann?")}
                        </label>
                        {/* <input
                          name="dateOfJoining"
                          type="date"
                          id="dateOfJoining"
                          //rows="3"
                          // defaultValue={dateOfBirth}
                          value={dateOfBirth}
                          onChange={(event) =>console.log(event)}
                          className="form-control Choose Branch for Idea Idea for Specific Location"
                        /> */}
                        <DatePicker
                          name="dateOfJoining"
                          id="dateOfJoining"
                          className="form-control select-bar"
                          selected={dateOfJoin}
                          onChange={date => handleJoindate(date)}
                          // dateFormat="YYYY/MM/DD"
                          dateFormat="dd/MM/yyyy"
                          // peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          placeholderText={t("Select join date...")}
                          dropdownMode="select"
                          maxDate={new Date()}
                        />
            </div>
          }
          <div className="form-group col-lg-12 addtipssearch">
            <label className="textcolor1 addlunchlable" htmlFor="idea">
              {t("Title *")}
            </label>
            <input
              className="form-control"
              name="idea"
              id="idea"
              value={idea}
              onChange={(event) => handleInput(event)}
              placeholder={t("Add an idea...")}
              required
            />
          </div>
          {popupstate1 === true && (
            <Popup
              show={message !== "" ? true : false}
              onHide={closePopup}
              size={"lg"}
              title={title}
              body={message}
            />
          )}
          {popupstate === true && (
            <IdeaboxPopup
              show={message !== "" ? true : false}
              onHide={closePopup}
              size={"lg"}
              title={title}
              body={message}
            />
          )}
          <div className="form-group col-lg-12">
            <label className="textcolor1 addlunchlable" htmlFor="description">
              {t("What is your feedback/remark/complaint?")}
            </label>
            <textarea
              name="description"
              type="text"
              id="description"
              //rows="3"
              value={ideaDescription}
              onChange={(event) => handleInput(event)}
              className="form-control"
              placeholder={t("Add your feedback...")}
            />
          </div>
          {/* <div className="form-group  addtipssearch">
            <label className="textcolor1 addlunchlable" htmlFor="work-status">
              {t("How long have you been working at Bleckmann?")}
            </label>
            <input
              className="form-control"
              name="work-status"
              id="work-status"
              // value={idea}
              onChange={(event) => handleInput(event)}
              placeholder={t("Add ..")}
              // required
            />
          </div> */}
          <div className="form-group col-lg-12">
            <label className="textcolor1 addlunchlable" htmlFor="url">
              {t("Upload a video url")}
            </label>
            <input
              type="text"
              className="form-control"
              id="url"
              name="url"
              placeholder={t("Add an url...")}
              value={url}
              onChange={(event) => handleInputChange(event)}
            />
          </div>
          <div className="form-group col-lg-12 mb-3">
              <label className="textcolor1 sectionTitle" htmlFor="location">
                {t("Idea for specific location *")}
              </label>
              <Select
                name="location"
                id="location"
                className="lan-select select-bar form-control border-0 p-0 bg-transparent"
                multi={false}
                options={locationList}
                // isRequired
                onChange={(e) => handleIdeaLocation(e)}
                showNewOptionAtTop={false}
                value={selectedIdeaLocation}
                isClearable={true}
                // isDisabled={true}
                placeholder={t("-Select-")}
                // disabled ={isDisabled && !isToggled}
              />
              {locationError && <p className="text-danger error-message">{t("Location is required *")}</p>}
          </div>
          <div className="form-group col-lg-12 mb-3">
              <label className="textcolor1 sectionTitle" htmlFor="user-location">
                {isToggled == 0 ? t("User location") : t("Submitter location")}
              </label>
              <Select
                name="user-location"
                id="user-location"
                className="lan-select select-bar form-control border-0 p-0 bg-transparent"
                multi={false}
                options={submitterlocationList}
                // isRequired
                onChange={(e) => handleLocation(e)}
                showNewOptionAtTop={false}
                value={selectedLocation}
                isClearable={true}
                // isDisabled={true}
                placeholder={t("-Select-")}
                disabled ={isDisabled && !isToggled}
              />
              {/* {locationError && <p className="text-danger error-message">{t("*Location is required")}</p>} */}
          </div>
          <div className="form-group col-lg-12 addtipssearch">
            <div className="form-row">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                onError={onImagesError}
                // maxNumber={maxNumber}
                dataURLKey="data_url"
                maxFileSize={5 * 1024 * 1024}
              >
                {({
                  imageList,
                  onImageUpload,
                  // onImageRemoveAll,
                  // onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="col-md-12 pl-0">
                    <div
                      className="btn btn-light"
                      style={{ textAlign: "left", paddingLeft: "5px" }}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      {t("+ Add image")+" "+t("(Upload size 5 mb)")}
                    </div>
                    &nbsp;
                    {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                    {imageList.map((image, index) => (
                      // <div className="row">
                      <div key={index} className="col-md-12">
                        {/* <img src={image["data_url"]} alt="" width="100" /> */}
                        <div className="row col-md-12 m-auto px-0">
                          <p
                            className="col-md-12 px-0"
                            style={{ wordBreak: "break-word" }}
                          >
                            {image.file.name}
                            <span
                              onClick={() => onImageRemove(index)}
                              className="btn btn-link"
                            >
                              Remove
                            </span>
                          </p>
                          {/* <div className="image-item__btn-wrapper"> */}
                          {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                          {/* </div> */}
                        </div>
                      </div>
                      // </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </div>
            <div className="form-group  addtipssearch mb-0">
              <div className="form-row">
                <Files
                  // className='files-dropzone'
                  ref={ref}
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={['.txt', '.rtf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.pdf', '.odf', '.odg', '.odp', '.ods', '.odt', '.fodt', '.fods', '.fodp', '.fodg']}
                  multiple
                  // maxFiles={3}
                  // maxFileSize={10000000}
                  minFileSize={0}
                  maxFileSize={10 * 1024 * 1024}
                  clickable
                >
                  <div
                    className="btn btn-light col-md-12"
                    style={{ textAlign: "left", paddingLeft: "5px" }}
                  >
                    {t("+ Add document")+" "+t("(Upload size 10 mb)")}
                  </div>
                </Files>
              </div>
            </div>
            {documents.length > 0 ? (
              <div className="files-list mb-0">
                <div>
                  {documents.map((file) => (
                    <div className="row col-md-12 m-auto px-0" key={file.id}>
                      <p
                        className="col-md-12"
                        style={{ wordBreak: "break-word" }}
                      >
                        {file.name}
                        <span
                          onClick={() => filesRemoveOne(file)}
                          className="btn btn-group btn-link"
                        >
                          Remove
                        </span>
                      </p>
                      {/* <div className='files-list-item-content-item files-list-item-content-item-2'>{file.sizeReadable}</div> */}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>

          {!isToggled &&
            <div className="form-group col-lg-12 rounded-2 mb-3">
              <label className="textcolor1 addlunchlable" htmlFor="preffered-contact">
              {isToggled ? t("Preffered mode for follow-up communication") : t("Preffered mode for follow up communication *")}
              </label>
              <Select
                name="preffered-contact"
                id="preffered-contact"
                className="lan-select select-bar form-control border-0 p-0 bg-transparent"
                multi={false}
                options={contactList}
                onChange={(e) => handleContactMode(e)}
                showNewOptionAtTop={false}
                value={selectedItem}
                isClearable={true}
                placeholder={t("-Select-")}
                // isDisabled={true}
                // disabled ={isDisabled && !isToggled}
              />
              {contactStatus && <p className="text-danger error-message">{t("This field is required *")}</p>}
            </div>
          }
         </div>

          <div className="col-md-12 col-12 pl-0 px-0 text-center votePopup mt-3 mb-4">
            <button
              type="submit"
              className="btn  m-0 rateSave  bg-color font-weight-bold"
            >
              {t("Submit your idea")}
            </button>
          </div>

        </form>
          <span className="benji-img d-none d-md-none d-lg-block">
            <img src={benji} alt="benji" className="img-fluid"/>
          </span>
      </div>
    </div>
  );
};


export default Ideabox;
