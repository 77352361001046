import React, { useState, useEffect } from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
// import firebase from 'firebase/app';
import { useAppContext } from "../../Config/contextLib";
import Alert from 'react-bootstrap/Alert'
// import ModelLR from './images/modelLR.jpg';

const OtpVerify = (props) => {
// export default function OtpVerify() {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [message, setMessage] = useState('');

  const [otpStatus, setOtpStatus] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const history = useHistory();
  let DesktopMode;
  if(localStorage.getItem('DeviceMode')==='Desktop'){
    DesktopMode=true;
  }
  else{
    DesktopMode=false;
  }

  useEffect(() => {
    try{
      if (JSON.parse(isAuthenticated)) {
        history.push("/");
      }
    }catch(e){
      console.error(e);
    }
    setTimeout(function(){ setOtpStatus(false); }, 120000);
    // Auth.currentCredentials();
  }, []);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;
     
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        //Focus next input
        if (element.nextSibling && element.value !== "") {
            element.nextSibling.focus();
        }
        if (element.value === ""){
          if (index !== 0){ 
          element.previousSibling.focus();
        }
          setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);
          // {e => setOtp([...otp.map(v => "")])}
        }
    };

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': function (response) {
        //   // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
      }
    });
  };

  //resend otp function
  const signIn = () => {
    setMessage('')
    setUpRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    let mobile = props.location.state.number
    //Firebase function to send OTP to given number
    firebase.auth().signInWithPhoneNumber(mobile, appVerifier)
      .then(function (confirmationResult) {
        window.confirmationResult = confirmationResult;
        setOtpStatus(true)
      })
      .catch(function (error) {
        setOtpStatus(false)
        setOtp(new Array(6).fill(""))
        setMessage('Something wrong, please try again later')
        return;
      });
  }

//verify or send function
  function onSubmitOtp(event) {
    event.preventDefault();
    setMessage("")
    if (otp === ''){
      setOtp(new Array(6).fill(""))
      //resend otp function
      signIn()
    }else{ 
      //verify otp
      let optConfirm = window.confirmationResult;
      var Otp = otp.join("")
        optConfirm
          .confirm(Otp)
          .then(function (result) {
            // User signed in successfully.
            if (localStorage.getItem('lastLoginDate') === "null") {
              userHasAuthenticated(false);
              localStorage.setItem("isAuthenticated", false);
              localStorage.setItem('firstlogin',true);
              localStorage.setItem('termsCondition',false);
              document.body.classList.remove('login');
              history.push("/termsConditions");
            } else { 
              userHasAuthenticated(true);
              localStorage.setItem("isAuthenticated", true);
              document.body.classList.remove('login');
              history.push(props.location.state.destinationUrl);
              // history.push("/");
            }
          })
          .catch(function (error) {
            setMessage("Incorrect code");
            setOtpStatus(false)
            setOtp(new Array(6).fill(""))
          });
      }
    };

  const OtpVerifyPage=()=>{
    return(
    <section className="container customContainer">
        <div className="col-md-12 pt-5">
           <div className="row">
            <div className=""> <p className="browserback" onClick={history.goBack}>back</p></div>
              {/* <div className=""><a href="#"><p className="browserback">back</p></a></div> */}
             </div>
        </div>
        <div className="col-md-12 px-0">
        {message.length > 0 &&
                      <Alert variant="danger" onClose={() => setMessage("")} dismissible><p>{message}</p></Alert>
                    }
        </div>
        <div class="col-lg-6 m-auto TabletLayout">
        <form onSubmit={onSubmitOtp} className="mt-4 pt-1 otpform">
                         <h1 className="pagetitle textcolor1 col-12 text-left">Login</h1>
                       <div className="col-md-12 col-12 px-0" >
                        <div  className="row justify-content-center">
                        {otp.map((data, index) => {
                        return (
                            <input
                                className="otp-field"
                                type="number"
                                name="otp"
                                maxLength="1"
                                key={index}
                                value={data}
                                onChange={e => handleChange(e.target, index)}
                                onFocus={e => e.target.select()}
                            />
                            );
                        })}
                        </div>
                      {!otpStatus && <div className="col-md-12 text-center  mt-4">
                          <a className="textcolor1" id = "recaptcha-container" onClick= {signIn} style={{color: '#d20c14' }}>Resend code</a>
                      </div>}
                </div>  
                      {<div className="col-md-12 text-center ">
                          <button type="submit" className="btn btn-block mybtn bg-color tx-tfm mb-2">Confirm</button>
                      </div>}
                    </form>
                    </div>
        </section>
    )
  }
    return (
      <>
      {/*
      * Check desktop mode and split div for desktop 
     */}
      {
     DesktopMode === true ? <div className="col-md-12 layoutSpace">
            
            <div class="col-md-5 float-lg-left login-bg"></div>
     <div className = "col-md-7 float-lg-left tablet-full-width">
      {OtpVerifyPage()}
     </div>
   </div>:
   OtpVerifyPage()
   }
    </>
    );
}

export default OtpVerify;
